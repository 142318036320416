<template>
  <ul class="menu-nav">
    <router-link
      v-if="false"
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a
          :href="href"
          class="menu-link"
          @click="navigate"
        >
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/products"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="
        checkPermission('PRODUCT_VIEW') ||
        checkPermission('CATE_VIEW') ||
        checkPermission('PROPERTY_VIEW') ||
        checkPermission('PROVIDER_VIEW') ||
        checkPermission('BRAND_VIEW')
      "
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a
          :href="href"
          class="menu-link"
          @click="navigate"
        >
          <span class="menu-text">Sản phẩm</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/customers"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="checkPermission('CUSTOMER_VIEW')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a
          :href="href"
          class="menu-link"
          @click="navigate"
        >
          <span class="menu-text">Khách hàng</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/stocks"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="checkPermission('STOCK_VIEW')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a
          :href="href"
          class="menu-link"
          @click="navigate"
        >
          <small class="menu-text">Kho hàng</small>
        </a>
      </li>
    </router-link>

    <router-link
      to="/orders"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="checkPermissions(['ORDER_VIEW'])"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a
          :href="href"
          class="menu-link"
          @click="navigate"
        >
          <span class="menu-text">Phiếu tiếp nhận</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/bills"
      v-if="checkPermission('BILL_VIEW')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a
          :href="href"
          class="menu-link"
          @click="navigate"
        >
          <span class="menu-text">Bán hàng</span>
        </a>
      </li>
    </router-link>
    <!-- <router-link
      to="/mbf-transaction"
      v-if="checkPermission('BILL_VIEW')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"
            ><span class="text-primary">Mobi</span
            ><span class="text-danger">fone</span></span
          >
        </a>
      </li>
    </router-link> -->

    <router-link
      to="/trade-in"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="checkPermissions(['TRADE_IN_VIEW'])"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a
          :href="href"
          class="menu-link"
          @click="navigate"
        >
          <span class="menu-text">Thu cũ</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/accounting/transactions/cashbook"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="
        checkPermissions([
          'TRANSACTION_VIEW',
          'INSTALLMENT_VIEW',
          'DEBT_CREDIT_VIEW',
          'DEBT_INSTALLMENT_VIEW',
          'DEBT_ACCOUNTANT_VIEW',
          'ACCOUNTANT_VIEW',
        ])
      "
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a
          :href="href"
          class="menu-link"
          @click="navigate"
        >
          <span class="menu-text">Kế toán</span>
        </a>
      </li>
    </router-link>
     <router-link
      to="/reports"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="checkPermissions(['REPORT_VIEW'])"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Báo cáo</span>
        </a>
      </li>
    </router-link> 
    <router-link
      to="/reward/reward-category"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="checkPermissions(['REWARD_VIEW'])"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Hoa hồng</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/stores"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="
        checkPermission('STORE_VIEW') || checkPermission('ORDER_SOURCE_VIEW')
      "
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a
          :href="href"
          class="menu-link"
          @click="navigate"
        >
          <span class="menu-text">Cấu hình</span>
        </a>
      </li>
    </router-link>

    <!-- <div
      data-v-6c7fd7ba=""
      class="navi navi-spacer-x-0 p-0"
      style="margin-right: 12px"
    >
      <b-form-select
        size="sm"
        v-model="selectedMerchant"
        :options="merchantOptions"
        style="
          width: fit-content !important;
          font-weight: bold;
          font-size: 12px;
          height: 45px;
        "
        @change="onChangeMerchant"
      >
        <template #first>
          <b-form-select-option :value="null" disabled
            >-- Chọn công ty --</b-form-select-option
          >
        </template>
      </b-form-select>
    </div> -->

    <b-dropdown
      size="sm"
      id="dropdown-1"
      right
    >
      <template slot="button-content">
        <i
          style="font-size: 1rem"
          class="flaticon2-add-1"
        ></i>
        <!-- <span class="font-weight-bolder">Thêm mới</span> -->
      </template>
      <b-dropdown-item v-if="checkPermission('PRODUCT_INSERT')">
        <router-link to="/products/add-product">
          <span>
            <i
              style="font-size: 1rem"
              class="flaticon2-add-1"
            ></i>
            &nbsp; Sản phẩm</span>
        </router-link>
      </b-dropdown-item>
      <b-dropdown-item v-if="checkPermission('ORDER_INSERT')">
        <router-link to="/orders/add-order">
          <span>
            <i
              style="font-size: 1rem"
              class="flaticon2-add-1"
            ></i>
            &nbsp; Đơn hàng</span>
        </router-link>
      </b-dropdown-item>
      <b-dropdown-item v-if="checkPermission('BILL_INSERT')">
        <router-link to="/bills/add-bill">
          <span>
            <i
              style="font-size: 1rem"
              class="flaticon2-add-1"
            ></i>
            &nbsp; Bán lẻ</span>
        </router-link>
      </b-dropdown-item>
      <b-dropdown-item v-if="checkPermission('BILL_INSERT')">
        <router-link to="/wholesaleInvoices/add-wholesale-invoice">
          <span>
            <i
              style="font-size: 1rem"
              class="flaticon2-add-1"
            ></i>
            &nbsp; Bán buôn</span>
        </router-link>
      </b-dropdown-item>
      <b-dropdown-item v-if="checkPermission('STOCK_IMPORT_INSERT')">
        <router-link to="/stocks/add-stock">
          <span>
            <i
              style="font-size: 1rem"
              class="flaticon2-add-1"
            ></i>
            &nbsp; Nhập kho</span>
        </router-link>
      </b-dropdown-item>
      <b-dropdown-item v-if="checkPermission('STOCK_EXPORT_INSERT')">
        <router-link to="/export-stocks/add-export-stock">
          <span>
            <i
              style="font-size: 1rem"
              class="flaticon2-add-1"
            ></i>
            &nbsp; Xuất kho</span>
        </router-link>
      </b-dropdown-item>
      <b-dropdown-item v-if="checkPermission('STOCK_INSERT')">
        <router-link to="/transfer-stocks/add-transfer-stock">
          <span>
            <i
              style="font-size: 1rem"
              class="flaticon2-add-1"
            ></i>
            &nbsp; Chuyển kho</span>
        </router-link>
      </b-dropdown-item>
      <!-- <b-dropdown-item @click="onClickToLink()">
        <a
          class="font-weight-bold"
          href="https://docs.google.com/forms/d/e/1FAIpQLSetWcf1SRsZjz2fnvL7BlLvavccBI9b_nNh1LspDrd4XjTxBA/viewform"
          target="_blank"
        >
          <span>
            <i style="font-size: 1rem" class="flaticon2-add-1"></i>
            &nbsp; Ý kiến đóng góp</span
          >
        </a>
      </b-dropdown-item> -->
      <!-- <b-dropdown-item
        class="text-primary"
        href="https://docs.google.com/spreadsheets/d/1vxRlLEpI9il0OX_8lVBTK6FlkzQ4z_g7NNIGcSszZAM/edit#gid=1670775653"
        target="_blank"
      >
        <span class="text-primary">
          <i style="font-size: 1rem" class="flaticon2-add-1"></i>
          &nbsp; CTKM Marketing</span
        >
      </b-dropdown-item> -->
      <!--
      <b-dropdown-item v-if="checkPermission('TRANSACTON_INSERT')">
        <router-link to="/wholesaleInvoices/add-wholesale-invoice">
          <span>
            <i style="font-size: 1rem" class="flaticon2-add-1"></i>
            &nbsp; Thu chi ngân hàng</span
          >
        </router-link>
      </b-dropdown-item> -->
    </b-dropdown>
  </ul>
</template>
<style scoped>
.font-style {
  font-size: 0.8rem;
}
</style>
<script>
import SavaDataUtil from '../../../utils/saveDataToLocal';
export default {
  data() {
    return {
      selectedMerchant: 1,
      merchantOptions: [
        { value: 1, text: 'Di Động Việt (DDV)' },
        { value: 2, text: 'Thế Giới Máy Cũ (TGMC)' },
      ],
    };
  },
  name: 'KTMenu',
  mounted() {
    this.selectedMerchant = SavaDataUtil.getData('merchantId') || 1;
  },
  methods: {
    onChangeMerchant() {
      SavaDataUtil.saveData('merchantId', this.selectedMerchant);
      this.$router.go(this.$router.currentRoute);
    },
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1;
    },
    checkPermission(condition) {
      if (SavaDataUtil.checkPermission(condition)) {
        return true;
      } else {
        return false;
      }
    },
    checkPermissions(conditions) {
      if (SavaDataUtil.checkPermissions(conditions)) {
        return true;
      } else {
        return false;
      }
    },
    onClickToLink() {
      window.open(
        'https://docs.google.com/forms/d/e/1FAIpQLSetWcf1SRsZjz2fnvL7BlLvavccBI9b_nNh1LspDrd4XjTxBA/viewform',
        '_blank',
      );
    },
  },
};
</script>
